.scores_wrapper::-webkit-scrollbar {
  width: 2px;
}
/* Track */
.scores_wrapper::-webkit-scrollbar-track {
  background: red;
}
/* Handle */
.scores_wrapper::-webkit-scrollbar-thumb {
  background: #ffd252;
}

.scrollable-element::-webkit-scrollbar {
  width: 5px;
}
.scrollable-element {
  scrollbar-width: thin;
  scrollbar-color: blue yellow;
}
.scrollable-element::-webkit-scrollbar-track {
  background: #f7f7f7;
}
.scrollable-element::-webkit-scrollbar-thumb {
  background-color: #ffd252;
  border-radius: 6px;
  border: 3px solid #ffd252;
}
